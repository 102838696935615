import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ASButton } from "../../../shared/as-button/as-button";
import "./../../../styles/utility.scss";
import styles from "./../../../styles/_exports.module.scss";
import "./connection-list.scss";
import moment from "moment";
import "./../../../App.scss";
import UpgradeButtonView from "../../common/upgradeButton/UpgradeButtonView";
import { useSelector } from "react-redux";
import DefaultView from "../../common/defaultView/DefaultView";
import { useASContext } from "../../../controllers/context-api/as-context";
import { AppEnum } from "../../../constants/app-enum";


export const DataSourcesListView = (props) => {
    const remainingFeatureBalance = useSelector((state) => state.remainingFeatureBalance);
    const { dataSources, confirmed ,responseReceived} = props;
    const asContext = useASContext();
    return (
        <div>
            <div className="d-flex justify-space-between">

                <div>
                    <Typography
                        sx={{
                            marginBottom: 0, color: styles.secondaryColor,
                            fontSize: '14px'
                        }}
                        gutterBottom
                        variant="body2"
                        component="div"
                    >
                        Total <i className="font-weight-bold"> {dataSources.length}</i>
                    </Typography>

                    <Typography
                        className="font-weight-bold"
                        sx={{
                            marginBottom: 0, color: styles.secondaryColor, fontFamily: styles.poppins,
                            fontSize: '20px', fontWeight: 'bold'
                        }}
                        gutterBottom
                        variant="body1"
                        component="div"
                    >
                        Connections
                    </Typography>
                </div>

                { asContext?.tenants?.selectedTenant?.UserRoleId === AppEnum?.UserRoleIds?.CompanyAdmin ?
                    (remainingFeatureBalance === null ? ( "" ) : (
                        remainingFeatureBalance <= 0 ? (
                            <UpgradeButtonView balanceFeature="CONNECTIONS" />

                        ) : (
                            <div className="add-new-btn-data">

                                <ASButton sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                                    onClick={() => { }}
                                    rounded={true}
                                    primary={true}
                                >
                                    <Link className="new-link" to="/connections/add-connection" state={{dataSources}}>
                                        <i className="plus-icon">+</i> ADD NEW
                                    </Link>
                                </ASButton>

                            </div>
                        )
                    )) : ("")
                }

            </div>

            {
                !(asContext.subscriptionValidation.isSubscriptionExist) ? (
                    <DefaultView
                        title="You have no subscription added"
                        firstPart="Click on "
                        buttonLink={"/checkout"}
                        buttonText="checkout"
                        secondPart= " to subscribe." 
                    />
                ) : (
                    remainingFeatureBalance === null  ? (""): (responseReceived && dataSources != null && dataSources.length == 0? (
                        asContext?.tenants?.selectedTenant?.UserRoleId === AppEnum?.UserRoleIds?.CompanyAdmin ?
                        <div>
                            <DefaultView
                                title="You have not added any connection yet."
                                firstPart="Click on "
                                buttonLink={"/connections/add-connection"}
                                buttonText="Add"
                                secondPart= " to add your first connection." 
                            />
                    </div>:""
                    ): (responseReceived && dataSources != null && dataSources?.length > 0? (
                    <div className="card-container">
                    {dataSources.map((source) => (
                        <Card sx={{ width: 261, height: 124, backgroundColor: !source.isActive ? '#F2F2F2' : '' }} key={source.key} className='data-source-card'>
                            <Link
                                className="new-link card-link"
                                to={asContext?.tenants?.selectedTenant?.UserRoleId === AppEnum?.UserRoleIds?.CompanyAdmin 
                                    ? `/connections/edit-connection/${source.uId}` 
                                    : ""}
                            >
                                <CardActionArea
                                    sx={{ display: "flex", padding: "20px 10px", height: "100%" }}
                                >
                                    {
                                        source.iconImage &&
                                        <CardMedia
                                            component="img"
                                            height="50"
                                            sx={{ width: "auto !important" }}
                                            image={`/assets/${source.iconImage}`}
                                            alt={source.iconImage}
                                        />
        
                                    }
        
                                    <CardContent>
        
                                        <Typography
                                            sx={{ lineHeight: "1.3", color: styles.secondaryColor, marginBottom: 0 }}
                                            gutterBottom
                                            variant="body1"
                                            component="div"
                                            className="datasource-name"
                                        >
                                            {/* {source.datasourceName} */}
                                            {source.name}
                                        </Typography>
        
                                        <Typography
                                            sx={{ color: styles.mutedColor, fontSize: "0.6rem", lineHeight: "1" }}
                                            variant="caption"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            Last Accessed
                                        </Typography>
        
                                        <Typography
                                            sx={{ color: styles.mutedColor, fontSize: "0.6rem", fontWeight: 600, marginBottom: "2px" }}
                                            variant="body2"
                                            color="text.secondary"
                                            component="div"
                                        >
                                            {source?.lastAccessed ? moment.utc(source?.lastAccessed).local().format('DD-MM-YYYY hh:mm A')
                                                : moment.utc(source?.modifiedDateTime).local().format('DD-MM-YYYY hh:mm A')}
                                        </Typography>
        
                                        <Typography
                                            sx={{ marginBottom: 0, color: styles.secondaryColor, backgroundColor: "#dce6f38f", fontSize: "11px", fontWeight: 500, padding: "2px 3px 3px 3px", borderRadius: "5px" }}
                                            gutterBottom
                                            variant="body3"
                                        // component="div"
                                        >
                                            {
                                                source.totalCollection > 1
                                                    ? `${source.totalCollection} collection(s)`
                                                    : source.totalCollection === 1
                                                        ? `${source.totalCollection} collection`
                                                        : "No collections"
                                            }
                                        </Typography>
        
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                        
                    ))} </div> ): (<div></div>)))
                ) 
            }           
        </div>
    );
};
