import React, { useEffect, useRef, useState } from 'react'
import ColumnSelectionPopup from '../../../components/data-collections/column-selection-popup/column-selection-popup'
import BasicInformationController from '../add-new-data-collection/basic-information/basic-information-controller';
import * as collectionService from '../../../services/data-collection.service';
import _ from 'lodash';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { testData } from '../../../services/test-data.service';
import { AppEnum } from '../../../constants/app-enum';
import { getDateRange } from './date-filter-controller/date-filter-helper';

export default function ColumnSelectionController(props) {

    const { getColumnSchema, state, resetErrorMessages, setState, prevStepRef,
        setSelectedConnection, saveCollection, isBasicInfoSubmitted, setActiveStep, activeStep, isUpdatingCollection,
        isLoading, setIsLoading, selectedConnection, getCollectionObjectByType, prevCloudFilterationData, isCloudFilterationData,
        setIsCloudFilterationData,queryState,isOpenInDialog = true, handleAuthorizeAgain, isAuthorizeAgain } = props;

    const [columnsList, setColumnList] = useState([]);
    const [filterColumnsList, setFilterColumnsList] = useState([]);
    const [data, setData] = useState([]);
    const [sortModal, setSortModal] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [limitModal, setLimitModal] = useState(false);
    const [sortData, setSortData] = useState([]);
    const [limitData, setLimitData] = useState(null);
    const [currentSortIndex, setCurrentSortIndex] = useState();
    const [sortInput, setSortInput] = useState({
        orderBy: "",
        field: ""
    })
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [preview, setPreview] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [isNewColumSchema, setIsNewColumSchema] = useState(false);
    const [isNewColumFilter, setIsNewColumFilter] = useState(false);
    const [filterConfig, setFilterConfig] = useState({
        numberOfFilterGroupAllowed: 0,
        numberOfFilterPerGroupAllowed: 0,
        numberOfSortAllowed: 0,
        operatorAllowedInFilterGroup: [],
        operatorAllowedInFilter: [],
        filterTypeArray: [],
        filterGroupExceeded:"",
        filterPerGroupExceeded:"",
        SortExceeded:""
    });
    const [prevFilterationData, setPrevFilterationData] = useState({
        prevFilterData: [],
        prevSortData: [],
        prevLimitData: null,
        templateCollectionId: null
    });
    const [collectionFilterColumns, setCollectionFilterColumns] = useState([]);
    const [isColumnOrderChanged, setIsColumnOrderChanged] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSearchButton, setSelectedSearchButton] = useState(0);
    const [isSearchOptionsAvailable, setIsSearchOptionsAvailable] = useState(false);
    const [searchOptionsParameters, setSearchOptionsParameters] = useState([]);
    const { collectionUId: collectionUId } = useParams();
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const [maunalColumns,setManualColumns] = useState([]);
    const [columnOverriden,setColumnOverriden] = useState(false);
    const [previousTemplateCollectionId,setPreviousTemplateCollectionId] = useState(null);

    useEffect(() => {
        if(state?.templateCollectionId && state?.templateCollectionId  != previousTemplateCollectionId){
            getColumnSchema(state, formatColumList);
            setPreviousTemplateCollectionId(state?.templateCollectionId);
        }

        if (prevCloudFilterationData?.templateCollectionId){
            handleObjectChange(null, prevCloudFilterationData?.templateCollectionId)
        }
    }, [state?.templateCollectionId, state?.restDataTransformationScript])

    useEffect(() => {
        if (state?.collectionParameters.length > 0 && !isNewColumFilter && !isCloudFilterationData) {

            var filterParameter = state?.collectionParameters.filter(i => i.parameterName == "Filter");
            var sortParameter = state?.collectionParameters.filter(i => i.parameterName == "Sort");
            var limitParameter = state?.collectionParameters.filter(i => i.parameterName == "Limit");
            if (filterParameter && filterParameter?.length > 0) {
                var filter = JSON.parse(filterParameter[0].defaultValue);
                filter = filter.filter(group => group.filters.length > 0);

                filter.forEach((group) => {
                    group.filters.forEach((filter) => {
                        if (filter.dateLabelValue) {
                            const { startDate, endDate } = getDateRange(filter.dateLabelValue, filter?.conditionLabel);
                            if (startDate && endDate){
                                filter.value = startDate;
                                filter.highValue = endDate;
                            }
                        }
                    });
                });

                setFilterData(filter);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevFilterData: filter,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            if (sortParameter && sortParameter?.length > 0) {
                var sort = JSON.parse(sortParameter[0].defaultValue);
                setSortData(sort);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevSortData: sort,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            if (limitParameter && limitParameter?.length > 0) {
                var limit = JSON.parse(limitParameter[0].defaultValue);
                setLimitData(limit);
                setIsNewColumFilter(true);

                if (collectionUId) {
                    setPrevFilterationData(prevState => ({
                        ...prevState,
                        prevLimitData: limit,
                        templateCollectionId: state?.templateCollectionId
                    }));
                }
            }
            setIsCloudFilterationData(true);
        }
    }, [state?.collectionParameters])
    

    useEffect(() => {
        if (state?.collectionColumnSchema && state?.collectionColumnSchema.length > 0 && !isColumnOrderChanged) {
            executeData(false);
        }
    }, [columnsList])

    useEffect(() => {
        if ((selectedConnection && !state?.templateCollectionId) || collectionUId) {
            getCollectionObjectByType();
        }
    }, [collectionUId]);

    useEffect(() => {
        if (selectedConnection?.connectionTypeId) {
            getCollectionFilterConfig(selectedConnection?.connectionTypeId);
        }
    }, [selectedConnection])

    useEffect(() => {
        getCollectionFilterColunms();
    }, [])

    useEffect(() => {
        if (state?.cloudCollectionObjects.length > 0 && state?.templateCollectionId){
            let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == state?.templateCollectionId);
            if (object){
                getTemplateCollectionParameters(object?.templateCollectionUId);
            }
        }
    }, [state?.cloudCollectionObjects, state?.templateCollectionId])

    useEffect(() => {
        if(state?.cloudCollectionError && state?.cloudCollectionError?.data?.data?.access_token){
            getColumnSchema(state, formatColumList);
            setState((prevState) => { return { ...prevState, cloudCollectionError: null } });
        }
    }, [state?.cloudCollectionError])

    useEffect(()=>{
        if(maunalColumns.length > 0 && columnsList.length > 0 && !columnOverriden){
            
            maunalColumns.forEach((manualColumn) => {
                let column = columnsList.find((col) => col.columnName === manualColumn.columnName);
            
                if (column) {
                    Object.assign(column, {
                        displayName: manualColumn.displayName,
                        isSelected: column.isSelected || false,
                        dataTypeName: column.dataTypeName, 
                        isManuallyCreated: true,
                        displayFormat: column.displayFormat || manualColumn.displayFormat,
                        isDefault: manualColumn.isDefault,
                        isFilterable: manualColumn.isFilterable,
                        isSortable: manualColumn.isSortable,
                        notQuerable: manualColumn.notQuerable,
                        displayOrder: column.displayOrder 
                    });
                }
            });
            

            const sortedColumnsList = _.orderBy(columnsList,['isSelected', 'displayOrder', column => column.displayName.toLowerCase()],['desc', 'asc', 'asc']); 

            setColumnOverriden(true);

            setColumnList(sortedColumnsList);
        }

    },[maunalColumns,columnsList])

    const getCollectionFilterColunms = () => {
        collectionService.getCollectionFilterColumns()
            .then((response) => {
                if (response?.data) {
                    setCollectionFilterColumns(response?.data);
                }
            })
    }

    const getCollectionFilterConfig = (connectionTypeId) => {
        collectionService.getCollectionFilterConfig(connectionTypeId)
            .then((response) => {
                if (response?.data) {
                    setFilterConfig({
                        numberOfFilterGroupAllowed: response.data?.numberOfFilterGroupAllowed,
                        numberOfFilterPerGroupAllowed: response.data?.numberOfFilterPerGroupAllowed,
                        numberOfSortAllowed: response.data?.numberOfSortAllowed,
                        operatorAllowedInFilterGroup: response.data?.operatorAllowedInFilterGroup,
                        operatorAllowedInFilter: response.data?.operatorAllowedInFilter,
                        filterTypeArray: response.data?.filterTypeArray,
                        filterGroupExceeded: response.data?.filterGroupExceeded,
                        filterPerGroupExceeded: response.data?.filterPerGroupExceeded,
                        SortExceeded: response.data?.sortExceeded
                    });
                }
            })
    }

    const formatColumList = (data) => {
        if (data) {
            var columns = data?.map((i) => {
                return {
                    columnName: i.columnName,
                    displayName: i.displayName,
                    isFilterable: i.isFilterable,
                    isSortable: i.isSortable,
                    isDefault: i.isDefault,
                    isSelected: i.isSelected,
                    dataTypeName: i.dataTypeName,
                    displayFormat: "",
                    isMultiSelect: i.isMultiSelect,
                    options: i.options,
                    notQuerable: i.notQuerable,
                    displayOrder : state?.collectionColumns?.length > 0 ? state?.collectionColumns?.find(c => c.columnName == i.columnName)?.displayOrder : 0,
                    isManuallyCreated : false
                }
            });
            setState((prevState) => { return { ...prevState, collectionColumnSchema: columns } });

            setIsColumnOrderChanged(false);
            setColumnList(columns);
        }
        return [];
    }

    const formateFilterData = () => {
        var colParameters = [...state?.collectionParameters];
        if (filterData?.length > 0) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == "Filter").length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == "Filter");
                if (prevCollectionParameter.length > 0) {
                    prevCollectionParameter[0].defaultValue = JSON.stringify(filterData?.filter(group => group.filters.length > 0));
                }
            }
            else {
                let collectionParameter = {
                    parameterName: "Filter",
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(filterData?.filter(group => group.filters.length > 0)),
                    displayName: "Filter",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }

        if (sortData.length > 0) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == "Sort").length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == "Sort");
                prevCollectionParameter[0].defaultValue = JSON.stringify(sortData);
            }
            else {
                let collectionParameter = {
                    parameterName: "Sort",
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(sortData),
                    displayName: "Sort",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }

        if (limitData) {
            if (colParameters?.length > 0 && colParameters.filter(i => i.parameterName == "Limit").length > 0) {
                var prevCollectionParameter = colParameters.filter(i => i.parameterName == "Limit");
                prevCollectionParameter[0].defaultValue = JSON.stringify(limitData);
            }
            else {
                let collectionParameter = {
                    parameterName: "Limit",
                    sysDataTypeID: null,
                    defaultValue: JSON.stringify(limitData),
                    displayName: "Limit",
                    isFixed: false,
                    description: "",
                    listColumnValue: "",
                    listColumnLabel: "",
                    isMultiSelectList: false,
                    dynamicListCollectionID: 0,
                    fixedListVariableId: 0,
                    parameterTypeCD: "",
                    inputFieldTypeCD: "",
                    sendAs: null,
                    variableUId: null,
                    isOptionalParameter: false
                }

                colParameters.push(collectionParameter);
            }

            setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
        }
    };

    const handleOnChangeSelectColumn = (event, index) => {

        if (!preview) {
            setPreview(true);
        }

        var newSelectedList = [...columnsList]
        var newFilteredList = [...filterColumnsList]
        
        let originalIndex = null;
        if (newFilteredList?.length > 0){
            const columnInFilteredList = newFilteredList[index];
            originalIndex = newSelectedList?.findIndex(i => i.columnName  === columnInFilteredList?.columnName);
        }

        if (index == null || index == undefined) {
            if (event.target.checked) {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = true;
                    return i;
                })
            }
            else {
                newSelectedList = newSelectedList.map((i) => {
                    i.isSelected = false;
                    return i;
                })
            }
        }
        else {
            if (originalIndex && originalIndex != null){
                newSelectedList[originalIndex].isSelected = event.target.checked;    
            }else{
                newSelectedList[index].isSelected = event.target.checked;
            }
        }

        if (newFilteredList?.length > 0) {
            if (index == null || index == undefined) {
                if (event.target.checked) {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = true;
                        return i;
                    })
                }
                else {
                    newFilteredList = newFilteredList.map((i) => {
                        i.isSelected = false;
                        return i;
                    })
                }
            }
            else {
                newFilteredList[index].isSelected = event.target.checked;
            }
            setFilterColumnsList(newFilteredList);
        }

        let maxDisplayOrder = Math.max(...newSelectedList.filter(i => i.isSelected).map(i => i.displayOrder || 0));
        if (maxDisplayOrder !== 0){
            let increment = 1;
            newSelectedList = newSelectedList.map(i => {                
                if (i.isSelected && (i.displayOrder == undefined || i.displayOrder == null)) {
                    const updatedColumn = { ...i, displayOrder: maxDisplayOrder + increment };
                    increment++;
                    return updatedColumn;
                }
                return i;
            });
        }


        setColumnList(newSelectedList);
        setIsColumnOrderChanged(false);
        setColumnList(newSelectedList);
        setState((prevState) => { return { ...prevState, collectionColumnSchema: newSelectedList } });

    }

    const executeData = (previewTrue = false) => {
        if (preview && !previewTrue) {
            return;
        }
        else if (preview && previewTrue) {
            setPreview(false);
        }

        setSearchValue('');
        setFilterColumnsList([]);
        if (searchInputRef?.current){
            searchInputRef.current.value = '';
        }

        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true, restBody: JSON.stringify(filterData) } });
        resetErrorMessages();

        var cloudCollectionObject = state.cloudCollectionObjects.filter(obj => obj.templateCollectionId === state.templateCollectionId)[0];

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: state.collectionParameters,
            collectionColumns: preview ? state?.collectionColumnSchema?.filter((i) => i.isSelected) : columnsList?.filter((i) => i.isSelected),
            uId: state.uId,
            returnFlatternData: !cloudCollectionObject?.returnRawData,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: getFilterData(),
            collectionSorts: sortData,
            collectionLimit: limitData,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : cloudCollectionObject?.returnRawData
        }

        formateFilterData();
        testData(testDataCollectionModal,setData,null,null,true,setState,setIsLoading);

    };

    const getFilterData = () => {
        const filterGroups = filterData?.map(group => {
            const filters = group?.filters.map(item => ({
                field: item.field,
                value: item.value,
                operator: item.operator,
                condition: item.condition,
                displayName: item.displayName,
                highValue: item.highValue,
                values: item.values,
                dateLabel: item.dateLabel,
                dataTypeName: item.dataTypeName,
                valueLabel: item.valueLabel,
                dateLabelValue: item.dateLabelValue,
                conditionLabel: item.conditionLabel,
                isMultiSelect: item.isMultiSelect,
            }));
            return { filters: filters, operator: group?.operator };
        });

        if (!filterGroups || filterGroups.length === 0) {
            return [];
        }

        return [{ filterGroups: filterGroups }];
    };

    const openSortModal = () => {
        setSortModal(true);
        setFilterModal(false)
        setLimitModal(false)
    }

    const closeSortModal = () => {
        setSortModal(false);
    }

    const openFilterModal = () => {
        setFilterModal(true);
        setSortModal(false);
        setLimitModal(false)
    }

    const closeFilterModal = () => {
        setFilterModal(false);
    }

    const openLimitModal = () => {
        setLimitModal(true)
        setFilterModal(false);
        setSortModal(false);
    }

    const closeLimitModal = () => {
        setLimitModal(false)
    }

    const handleChangeObject = () => {
        if (queryState?.isForDataCollections){
            navigate(-1);
            return;
        }
        else if(queryState?.CloseWindowOnPopupClose){
            window.close();
        }
        else{
            setActiveStep(activeStep - 1)
        }
    }

    const onChangeSortInputs = (event, input) => {
        if (input == "order") {
            setSortInput((prevState) => {
                return { ...prevState, orderBy: event.target.value }
            });
        }
        else {
            setSortInput((prevState) => {
                return { ...prevState, field: event.target.value }
            });
        }
    }

    const addSort = () => {
        console.log(sortInput);
        var newSortData = [...sortData];

        if (currentSortIndex != null) {
            newSortData[currentSortIndex].orderBy = sortInput.orderBy;
            newSortData[currentSortIndex].field = sortInput.field;

            setCurrentSortIndex(null);
        }
        else {
            newSortData.push(sortInput);
        }

        setSortData(newSortData);

        setSortInput((prevState) => {
            return { ...prevState, field: "", orderBy: "" }
        });

        setSortModal(false);
    };

    const handleDeleteSort = (index) => {
        var newSortData = [...sortData];
        newSortData.splice(index, 1);
        setSortData(newSortData);
    }

    const handleClickSort = (index) => {
        var currentSortdata = sortData[index];
        setSortInput(currentSortdata);
        setCurrentSortIndex(index)
        setSortModal(true);
    }

    const handleSave = () => {
        if (collectionUId || queryState?.CollectionUId) {
            saveCollection();
        }
        else {
            setIsDialogOpen(true)
        }
    }

    const handleObjectChange = (event, prevCloudObjectUId) => {
        let changedTemplateCollectionId = event ? event.target.value : state?.templateCollectionId;
        let object = state?.cloudCollectionObjects?.find(i => i.templateCollectionId == changedTemplateCollectionId);
        if (object) {
            const prevData = prevFilterationData?.templateCollectionId ? prevFilterationData: prevCloudFilterationData;
            setState((prevState) => { return { ...prevState, templateCollectionId: object?.templateCollectionId, restDataTransformationScript: object?.restDataTransformationScript } });
            if (state?.collectionParameters && (prevData?.templateCollectionId != object?.templateCollectionId)) {
                var colParameters = [...state?.collectionParameters];
                if (colParameters?.length > 0) {
                    if (colParameters.filter(i => i.parameterName == "Filter").length > 0) {
                        var filterParam = colParameters.filter(i => i.parameterName == "Filter");
                        if (filterParam.length > 0) {
                            filterParam[0].defaultValue = "[]";
                        }
                    }
                    if (colParameters.filter(i => i.parameterName == "Sort").length > 0) {
                        var sortParam = colParameters.filter(i => i.parameterName == "Sort");
                        if (sortParam.length > 0) {
                            sortParam[0].defaultValue = "[]";
                        }
                    }
                    if (colParameters.filter(i => i.parameterName == "Limit").length > 0) {
                        colParameters = colParameters?.filter(i => i.parameterName !== "Limit");
                        setState((prevState) => { return { ...prevState, collectionParameters: colParameters } });
                        // var limitParam = colParameters.filter(i => i.parameterName == "Limit");
                        // if (limitParam) {
                        //     limitParam[0].defaultValue = "[]";
                        // }
                    }
                }
                setFilterData([]);
                setSortData([]);
                setLimitData(null);
                closeFilterModal();
                closeSortModal();
                closeLimitModal();
            }
            else {
                if (prevData?.prevFilterData?.length > 0) {
                    setFilterData(prevData?.prevFilterData);
                }
                if (prevData?.prevSortData?.length > 0) {
                    setSortData(prevData?.prevSortData);
                }
                if (prevData?.prevLimitData) {
                    setLimitData(prevData?.prevLimitData);
                }
                setIsNewColumFilter(true);
            }
        }
    }

    const onSearchHandler = (e) => {
        let searchedKey = e.target.value
        setSearchValue(searchedKey);
        resetErrorMessages();

        let filteredDataColunmList = [];
        let searchedColunmList = [];

        if (searchedKey) {
            filteredDataColunmList = columnsList;
            searchedColunmList = [];

            _.forEach(filteredDataColunmList, function (column) {
                if (_.includes(column?.displayName?.toLowerCase().trim(), searchedKey?.toLowerCase().trim()))
                    searchedColunmList.push(column);
            })
        }
        else{
            setSearchValue('');
            searchInputRef.current.value = '';
        }

            setFilterColumnsList(searchedColunmList);
    }

    const handleSearchOptionsButtonClick = (index) => {
        setSelectedSearchButton(index);
    };

    const getTemplateCollectionParameters = (templateCollectionUId) => {

        collectionService.getCollectionDetails(templateCollectionUId)
            .then((response) => {
                if (response?.data) {
                    let prevCollection = response?.data;
                    if (prevCollection) {

                        if(prevCollection?.restDataTransformationScript){
                            setState((prevState) => { return { ...prevState, restDataTransformationScript: prevCollection?.restDataTransformationScript } });
                        }

                        const manuallyCreatedColumns = prevCollection.collectionColumns.filter(mc => mc.isManuallyCreated);

                        if(manuallyCreatedColumns.length > 0){
                            setManualColumns(manuallyCreatedColumns);
                        }

                        if (prevCollection.collectionParameters?.length > 0){
                            const hasOtherParams = prevCollection?.collectionParameters?.some(
                                param => param.parameterTypeCD.toLowerCase() !== "fixed hidden"
                              );
                              if (hasOtherParams){
                                  let updatedParams = updateParameterValues(prevCollection.collectionParameters);
                                  setIsSearchOptionsAvailable(true);

                                //sorting the dropdowns params
                                let sortedSubsetParams = updatedParams
                                                    ?.filter(param => param.sysDataTypeId  == AppEnum.DataTypeId.DynamicList || param.sysDataTypeId == AppEnum.DataTypeId.List)
                                                    ?.sort((a, b) => a.displayName.localeCompare(b.displayName));

                                // Merge the sorted subset back into the original array, keeping the order of other elements
                                updatedParams = updatedParams?.map(param => {
                                                if (param.sysDataTypeId  == AppEnum.DataTypeId.DynamicList || param.sysDataTypeId  == AppEnum.DataTypeId.List) {
                                                    return sortedSubsetParams.shift();  // Replaces with the sorted element
                                                }
                                                return param;
                                });
                                  setSearchOptionsParameters(updatedParams);
                              }
                              else{
                                setIsSearchOptionsAvailable(false);
                                setSearchOptionsParameters([]);
                              }
                        }
                        else{
                            setIsSearchOptionsAvailable(false);
                            setSearchOptionsParameters([]);
                        }
                    }
                }
            })
    }

    const updateParameterValues = (params) => {
        return params.map(param => {
            if (param.sysDataTypeId == AppEnum.DataTypeId.DynamicList && param?.complexTypeValue) {
                    const complexType = JSON.parse(param.complexTypeValue);
                    param.parameterValue = complexType?.DefaultValue
                
            }
            else if (param.sysDataTypeId == AppEnum.DataTypeId.List && param?.complexTypeValue) {
                    const complexTypeArray = JSON.parse(param.complexTypeValue);
                    const defaultItem = complexTypeArray?.find(item => item.Default === true);
                    param.parameterValue = defaultItem?.Value;
                }
            else {
                //param.parameterValue = param.defaultValue;
            }
            return param;
        });
    };

    const executedData = () => {
        setIsLoading(true);

        setState((prevState) => { return { ...prevState, isTestingConnection: true, restBody: JSON.stringify(filterData) } });
        resetErrorMessages();

        var cloudCollectionObject = state.cloudCollectionObjects.filter(obj => obj.templateCollectionId === state.templateCollectionId)[0];

        let testDataCollectionModal = {
            connectionUId: state.connectionUId,
            sysCollectionTypeId: state.sysCollectionTypeId,
            sourceName: state.sourceName,
            restRequestMethod: state.restRequestMethod,
            restRequestIsAsync: true,
            restSendAsCD: state.restSendAsCD,
            collectionParameters: searchOptionsParameters,
            collectionColumns: preview ? state?.collectionColumnSchema?.filter((i) => i.isSelected) : columnsList?.filter((i) => i.isSelected),
            uId: state.uId,
            returnFlatternData: !cloudCollectionObject.returnRawData,
            sourceNamePart4: state.sourceNamePart4,
            templateCollectionId: state.templateCollectionId,
            collectionFilters: getFilterData(),
            collectionSorts: sortData,
            collectionLimit: limitData,
            restDataTransformationScript : state?.restDataTransformationScript,
            returnRawData : cloudCollectionObject.returnRawData
        }

        formateFilterData();
        testData(testDataCollectionModal,setData,null,null,true,setState,setIsLoading,true);

    };
    

    return (
        <>
            <ColumnSelectionPopup columnsList={columnsList}
                rowData={state?.testedDataList}
                handleOnChangeSelectColumn={handleOnChangeSelectColumn}
                sortModal={sortModal}
                filterModal={filterModal}
                limitModal={limitModal}
                openSortModal={openSortModal}
                openFilterModal={openFilterModal}
                openLimitModal={openLimitModal}
                closeSortModal={closeSortModal}
                closeFilterModal={closeFilterModal}
                closeLimitModal={closeLimitModal}
                onChangeSortInputs={onChangeSortInputs}
                sortInput={sortInput}
                addSort={addSort}
                sortData={sortData}
                limitData={limitData}
                setLimitData={setLimitData}
                handleDeleteSort={handleDeleteSort}
                handleClickSort={handleClickSort}
                handleSave={handleSave}
                executeData={executeData}
                filterData={filterData}
                setFilterData={setFilterData}
                setSortData={setSortData}
                preview={preview}
                handleChangeObject={handleChangeObject}
                isLoading={isLoading}
                isDialogOpen={isDialogOpen}
                state={state}
                setState={setState}
                selectedConnection={selectedConnection}
                handleObjectChange={handleObjectChange}
                filterConfig={filterConfig}
                onSearchHandler={onSearchHandler}
                filterColumnsList={filterColumnsList}
                collectionFilterColumns={collectionFilterColumns}
                searchInputRef={searchInputRef}
                searchValue={searchValue}
                setColumnList={setColumnList}
                setIsColumnOrderChanged={setIsColumnOrderChanged}
                handleSearchOptionsButtonClick={handleSearchOptionsButtonClick}
                selectedSearchButton={selectedSearchButton}
                isSearchOptionsAvailable={isSearchOptionsAvailable}
                searchOptionsParameters={searchOptionsParameters}
                setSearchOptionsParameters={setSearchOptionsParameters}
                executedData={executedData}
                isOpenInDialog={isOpenInDialog}
                handleAuthorizeAgain={handleAuthorizeAgain}
                isAuthorizeAgain={isAuthorizeAgain}/>
            {isDialogOpen &&
                <BasicInformationController
                    resetErrorMessages={resetErrorMessages}
                    state={state}
                    setState={setState}
                    prevStepRef={prevStepRef}
                    setSelectedConnection={setSelectedConnection}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    saveCollection={saveCollection}
                    isBasicInfoSubmitted={isBasicInfoSubmitted}
                    isUpdatingCollection={isUpdatingCollection}
                    isCloudLoading={isLoading}
                />}
        </>

    )
}
